import * as React from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'formiojs/dist/formio.full.min.css';
import '@walmart-web/livingdesign-components/index.esm.css'
import './styles/App.css'
import { connect, Provider } from 'react-redux';
import { createMuiTheme, ThemeProvider } from '@material-ui/core';
import { Components } from '@formio/react';
import GDMSRoutes from './components/menu/GDMSRoutes'
import { DebugRouter } from './components/menu/DebugRouter';

// import {RouteComponentProps} from './polyfils/customRouter'

import customComponents from './components/custom';
import store from './store';

import { GlobalDonationState } from './models/globalDonationState';

import { selectedData } from './state/selectedData/selectedDataActions';
import { closeDonationApprovalRespDailog } from './state/donationOfApproval/donationOfApprovalActions';


const config = require(`./config.${process.env.NODE_ENV}.json`);
// set formio custom components
Components.setComponents(customComponents);

// injectService(testService);
/* tslint:disable */
export interface StateProps {
  selectedLang: any;
  loggedInUserDetail: any;
  refreshing: boolean;
  sidebarDetail: any
}


export interface DispatchProps {
  addSelectedDataToStore: (data: any) => void;
  closeDonationApprovalDialog: (data: any) => void;
}
class App extends React.Component<any & StateProps & GlobalDonationState> {

  public render() {
    // @ts-ignore
    window.config = config;
    let themePrimaryColor = '#041E42';
    let baseColor = '#F4F6F9';
    window.countryCode = this.props.loggedInUserDetail.countryCode;
    if (window.countryCode === 'GB') {
      themePrimaryColor = '#228B22 !important';
      baseColor = 'rgba(104, 165, 28, 0.1) !important';
    }

    return (
      <Provider store={store}>
        <ThemeProvider theme={createMuiTheme({
          typography: {
            fontFamily: [
              'Bogle',
              'Roboto'].join(',')
          },
          palette: {
            primary: {
              main: themePrimaryColor,
              light: baseColor,
              dark: '#002884',
              contrastText: '#fff',
            }
          },
          overrides: {
            MuiButton: {
              containedPrimary: {
                '&:hover': {
                  backgroundColor: themePrimaryColor
                }
              }
            },
            MuiListItem: {
              /* selected: {
                backgroundColor: themePrimaryColor,
                '&:hover': {
                  backgroundColor: themePrimaryColor
                }
              }, */
              root: {
                '&$selected': {
                  backgroundColor: themePrimaryColor,
                  '&:hover': {
                    backgroundColor: themePrimaryColor
                  }
                }
              }
            },
            MuiInputLabel: {
              outlined: {
                backgroundColor: '#F4F6F9',
                paddingLeft: 2,
                paddingRight: 2
              }
            }
          }
        })}>
          <DebugRouter>
            <GDMSRoutes
              selectedLang={this.props.selectedLang}
              addSelectedDataToStore={this.props.addSelectedDataToStore}
              refreshing={this.props.refreshing}
              userDetail={this.props.loggedInUserDetail}
              closeApprovalDialog={this.props.closeDonationApprovalDialog}
              sidebarDetail={this.props.sidebarDetail}
            />
          </DebugRouter>
        </ThemeProvider>
      </Provider>
    );
  }

}
const mapStateToProps = (state: GlobalDonationState): StateProps => ({
  selectedLang: state.selectedLang,
  loggedInUserDetail: state.loggedInUserState.userDetail,
  refreshing: state.loggedInUserState.loggedInUserloading,
  sidebarDetail: state.sidebarState
});

const mapDispatchToProps = (dispatch: React.Dispatch<any>): DispatchProps => ({
  addSelectedDataToStore: (data: any) => dispatch(selectedData(data)),
  closeDonationApprovalDialog: () => dispatch(closeDonationApprovalRespDailog())
});
export default (connect(mapStateToProps, mapDispatchToProps)(App));
