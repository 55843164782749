import { useLocation } from "react-router-dom";
import { useEffect } from "react";


type Props = {
    children: React.ReactNode
};
export const DebugRouter = ({ children }: Props) => {
    const location = useLocation();
    useEffect(() => {
        console.log('Location changed, location pathname:', location.pathname);
    }, [location]);

    return (<div>
        {children}
    </div>);
}